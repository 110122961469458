<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? $t("centers.add_center") : $t("centers.edit_center") }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="takenOn"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.date_of_capture')"
              label-for="takenOn"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.takenOn }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="plateNumber"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.plate_detected')"
              label-for="plateNumber"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.plateNumber }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="originalPlateNumber"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('lpr_matches.original_plate')"
              label-for="originalPlateNumber"
            >
              <b-form-input
                id="originalPlateNumber"
                v-model="itemData.originalPlateNumber"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.status')"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.status }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="cameraName"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.cameras')"
              label-for="cameraName"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.cameraName }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="country"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.country')"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.country }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="direction"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.direction')"
              label-for="direction"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.direction }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="speed" rules="required">
            <b-form-group
              :label="$t('lpr_matches.speed')"
              label-for="speed"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.speed }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="originalCountry"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.original_city')"
              label-for="originalCountry"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.originalCountry }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="confidence"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.confidential')"
              label-for="confidence"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.confidence }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ocrImageId"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.ocr_image_id')"
              label-for="ocrImageId"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.ocrImageId }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="signaled"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.signposted')"
              label-for="signaled"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.signaled }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="avgDigitHeight"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.digit_height')"
              label-for="avgDigitHeight"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.avgDigitsHeight }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="multiplateRate"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.multiplate_rate')"
              label-for="multiplateRate"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.multiplateRate }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="processingTimeOCR"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.processing_time_ocr')"
              label-for="processingTimeOCR"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.processingTimeOCR }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="kamikazeAlarm"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.kamikaze_alarm')"
              label-for="kamikazeAlarm"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.kamikazeAlarm }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="timeInZoneAlarm"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.time_in_zone_alarm')"
              label-for="timeInZoneAlarm"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.timeInZoneAlarm }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="blacklistAlarm"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.alarm_blacklist')"
              label-for="blacklistAlarm"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.blacklistAlarm }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="speedAlarm"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.alarm_speed')"
              label-for="speedAlarm"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.speedAlarm }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="prowlingAlarm"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.prowling_alarm')"
              label-for="prowlingAlarm"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.prowlingAlarm }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="vehicleModel"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.vehicle_model')"
              label-for="vehicleModel"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.vehicleModel }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="vehicleColor"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.vehicle_color')"
              label-for="vehicleColor"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.vehicleColor }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="crosstime"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.cross_time')"
              label-for="crosstime"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.crosstime }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="hasExit"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.has_exit')"
              label-for="hasExit"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.hasExit }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="signalId"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.signal_id')"
              label-for="signalId"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.signalId }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.category')"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.category }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="vehicleClass"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.vehicle_class')"
              label-for="vehicleClass"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.vehicleClass }}</p>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="region"
            rules="required"
          >
            <b-form-group
              :label="$t('lpr_matches.region')"
              label-for="region"
              :state="getValidationState(validationContext)"
            >
              <p class="text-capitalize">{{ itemData.region }}</p>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      originalPlateNumber: "",
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("lpr_coincidences/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            originalPlateNumber: itemData.value.originalPlateNumber,
          },
        };

        dispatch = store.dispatch("lpr_coincidences/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>