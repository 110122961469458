<template>
  <div>
    <toastification-progress-content
      ref="progressToast"
    />

    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
    />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <!-- Filters -->
    <filters
      :plate-filter.sync="plateFilter"
      :camera-filter.sync="cameraFilter"
      :camera-options="cameraOptions"
      :start-datetime.sync="startDatetimeFilter"
      :end-datetime.sync="endDatetimeFilter"
    />

      <!-- Table Container Card -->
      <b-card class="m-2" no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search')"
                />
                <b-button
                  @click="exportExcel()"
                  variant="success"
                  class="text-capitalize"
                  type="button"
                  :disabled="buttonExport"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="15"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          :select-mode="selectMode"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          selectable
          @row-selected="onRowSelected"
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 1) }}</span>
          </template>

          <template #head(takenOn)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(plateNumber)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(originalPlateNumber)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(envImageIds)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(status)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(cameraName)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(country)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(direction)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(speed)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(zoneId)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(originalCountry)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(confidence)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(ocrImageId)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(siganled)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(avgDigitHeight)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(multiplateRate)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(processingTimeOCR)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(kamikazeAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(timeInZoneAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(blacklistAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(speedAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(prowlingAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(vehicleModel)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(vehicleColor)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(crosstime)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(hasExit)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(signalId)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(category)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(vehicleClass)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(region)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(isAlarm)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(created_at)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(updated_at)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #cell(picture_plate)="data">
            <b-img :src="imageUrl + data.item.id"/>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="openEdit(data.item)" v-if="$can('update', 'Lpr')">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50 text-capitalize">{{
                    $t("actions.edit")
                  }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                  $t("table.showing", {
                    from: dataMeta.from,
                    to: dataMeta.to,
                    of: dataMeta.of,
                  })
                }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        class="m-2"
        v-if="selectedObject"
        no-body>
        <feather-icon
          icon="XIcon"
          class="mr-2 ml-auto mt-2 float-rigth"
          style="cursor: pointer"
          size="30"
          @click="selectedObject = null"
        />
        <div class="mx-2">
          <b-img fluid :src="generalImageUrl + selectedObject.id"/>
          <b-row class="m-1 p-1">
            <b-col>
              <b-img :src="imageUrl + selectedObject.id"/>
            </b-col>
            <b-col class="text-center" align-v="center" v-if="selectedObject.isAlarm">
              <!-- <p>{{ $t("lpr_matches.isAlarm")}} </p> -->
              <feather-icon
                icon="AlertOctagonIcon"
                size="35"
                class="align-middle text-body"
                stroke="red"
              />
            </b-col>
          </b-row>
          <b-list-group flush>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.plate_detected") }} :</b>
              {{ selectedObject.plateNumber }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize"> {{ $t("lpr_matches.cameras") }}
              :</b> {{ selectedObject.cameraName }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize"> {{ $t("lpr_matches.country") }}
              :</b> {{ selectedObject.country }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.date_of_capture") }} :</b> {{ selectedObject.takenOn }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize"> {{ $t("lpr_matches.speed") }}
              :</b> {{ selectedObject.speed }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.vehicle_maker") }} :</b>
              {{ selectedObject.vehicleMaker }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.vehicle_model") }} :</b>
              {{ selectedObject.vehicleModelo }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.vehicle_color") }} :</b>
              {{ selectedObject.vehicleColor }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.vehicle_class") }} :</b>
              {{ selectedObject.vehicleClass }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize"> {{ $t("lpr_matches.list") }}
              :</b> {{ concatArray(selectedObject.plate_lists) }}
            </b-list-group-item>
            <b-list-group-item><b class="text-capitalize">
              {{ $t("lpr_matches.reliability") }} :</b> {{ selectedObject.confidence }} %
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BContainer,
  BCardGroup,
  BCardText,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, onUnmounted, onMounted, computed, watch} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationProgressContent
  from '@core/components/toastification/ToastificationProgressContent.vue'
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Sidenav,
    Breadcrumb,
    Filters,
    BListGroup,
    BListGroupItem,
    BCard,
    BContainer,
    BCardGroup,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    Pagination,
    ToastificationProgressContent,
    vSelect,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const selectMode = 'single';
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const buttonExport = ref(false)
    const progressToast = ref(null)
    const progress = computed(() => store.getters['lpr_coincidences/getProgress'])
    const error = computed(() => store.getters['lpr_coincidences/getError'])
    const url = computed(() => store.getters['lpr_coincidences/getUrl'])

    const imageUrl = `${process.env.VUE_APP_BASE_URL}/v1/lpr_match/plate/image/`;
    const generalImageUrl = `${process.env.VUE_APP_BASE_URL}/v1/lpr_match/plate/general_image/`;
    const selectedObject = ref(null);

    store.dispatch('grid/findDownloadNotification', 'lpr-matches')
      .then(data => {
        if (data.length > 0) {
          buttonExport.value = true
          progressToast.value.show('Descarga', 'Generando archivo...', 'secondary')
        }
      })

    const exportExcel = () => {
      buttonExport.value = true
      store.dispatch('lpr_coincidences/resetProgress')
      progressToast.value.show('Descarga', 'Generando archivo...', 'secondary')

      store.dispatch('lpr_coincidences/getAll', {
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        plate: plateFilter.value,
        camera: cameraFilter.value,
        startDatetime: startDatetimeFilter.value,
        endDatetime: endDatetimeFilter.value,
        action: 'export'
      })
        .then(response => {
          store.dispatch('lpr_coincidences/setExportLprMatches', response.data.jobId)
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error exportando la información',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          buttonExport.value = false
          progressToast.value.close()
        })
    }

    const openEdit = (item) => {
      isSidebarActive.value = true
      itemEdit.value = item
      isAdd.value = false
    };
    const {t} = useI18nUtils()

    const toast = useToast()

    watch([progress], () => {
      progressToast.value.updateProgress(progress.value)
    })

    watch([error], () => {
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      buttonExport.value = false
      progressToast.value.close()
    })

    watch([url], () => {
      if (url.value) {
        const link = document.createElement('a');
        link.href = url.value;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        buttonExport.value = false
        progressToast.value.close()
      }
    })

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      plateFilter,
      cameraFilter,
      cameraOptions,
      startDatetimeFilter,
      endDatetimeFilter,
    } = useList();

    onMounted(() => {
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log(channel, data);
      })

      if (!window.Echo.connector.socket.connected) window.Echo.connect()

      window.Echo.private(`lpr-match`).listen(".create", (data) => {
        refetchData();
      })
    })

    onUnmounted(() => {
      window.Echo.leave(`lpr-match`)
    })

    const onRowSelected = (item) => {
      //console.log({item})
      selectedObject.value = item[0]
    }

    const concatArray = (objectArray) => {
      let string = "";

      objectArray.forEach(element => {
        string = string + element.name + ","
      });

      return string;
    }

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.analytics.lpr.lpr_matches");

    return {
      //Breadcrumb
      itemFilter,
      addItemFilter,
      nameNav,
      addItemFilterSearch,
      //Sidebar
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      selectMode,
      selectedObject,
      onRowSelected,
      concatArray,
      imageUrl,
      generalImageUrl,

      plateFilter,
      cameraFilter,
      cameraOptions,
      startDatetimeFilter,
      endDatetimeFilter,

      exportExcel,
      buttonExport,
      progress,
      progressToast,
    };
  },
};
</script>